import React from "react";
import styled, { css } from "styled-components";
import { components as UI } from './StyledElements'

const StyledImage = styled.img`
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${props => props.theme.colors.primary};
  }
  width: 1;
  height: 1;
  margin: 0px;
`;
const ListingContainer = styled.div`
  background-color: white;
  padding: 20px 10px;
`;
const ProductTitleLink = styled.a`
  &&& {
    h2: {
      color: red;
      &:hover {
        color: ${props => props.theme.colors.primaryLight};
      }
    }
  }
`;


const AnchorBuyButton = styled.a`

    text-decoration: none;
    padding: 0.5em;
    background-color: ${props => props.theme.colors.primaryLight};
    color: white;

     transition: all 250ms ease-in-out;
     &:hover {
      background-color: white;
    color: ${props => props.theme.colors.primary};
      opacity: 1;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primaryLight};
    }
    &:focus {
      background-color: white;
    color: ${props => props.theme.colors.primary};
      opacity: 1;
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primaryLight};
    }
`;

export const AmazonDetailListing = ({
  ASIN = "B07MGDXWS3",
  description,
  title,
  order,
  children
}) => {

  if(children) {
    return (
    <React.Fragment>
      {title && (
        <a
          target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >
          {title}
        </a>
      )}
      <ListingContainer>
        <a
          target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >
          <StyledImage
            border="0"
            src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${ASIN}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20`}
          />
        </a>
        <StyledImage
          src={`//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=${ASIN}`}
          alt=""
        />
      </ListingContainer>
      {!description && children ? (
        <a
          target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >
          {children}
        </a>
      ) : (
        description
      )}
    </React.Fragment>
  );
  } else {
    return (
     <React.Fragment>
      {title && (
        <a
          target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >
          <UI.h2>
          {order}
          <br></br>
          {title}
          </UI.h2>
        </a>
      )}
      <ListingContainer>
        <a
          target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >
          <StyledImage
            border="0"
            src={`//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${ASIN}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20`}
          />
        </a>
        <StyledImage
          src={`//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=${ASIN}`}
          alt=""
        />
      </ListingContainer>
      <AnchorBuyButton target="_blank"
          href={`https://www.amazon.com/gp/product/${ASIN}/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=${ASIN}&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5`}
        >BUY ON AMAZON</AnchorBuyButton>
      <p>{description}</p>
    </React.Fragment>
  );
  }
  
};

{
  /* <a target="_blank"  href="https://www.amazon.com/gp/product/0786965592/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0786965592&linkCode=as2&tag=thirtysevende-20&linkId=21a2099ea084f2c331a24a7a6b25edf5"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0786965592&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=0786965592" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}

const StyledIFrame = styled.iframe`
  &&& {
    width: 100%;
    height: 100%;
    border: none;

    div {
      background-color: red;
    }
    div .amazon .amzn-ad-container {
      .amzn-ad-logo-holder {
        display: none;
      }
    }
  }
`;

export const IFrameListing = ({ ASIN = "B07MGDXWS3" }) => {
  return (
    <ListingContainer>
      <StyledIFrame
        // style={{ width: 120, height: 240 }}
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        frameborder="0"
        src={`//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=thirtysevende-20&marketplace=amazon&region=US&placement=${ASIN}&asins=${ASIN}&linkId=e61ff93f77518911361766684e298a24&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff`}
      ></StyledIFrame>
    </ListingContainer>
  );
};
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=thirtysevende-20&linkId=4ed55cebfea9a59563c72033044f54d6"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}
{
  /* <a target="_blank"  href="https://www.amazon.com/gp/offer-listing/B07MGDXWRY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MGDXWRY&linkCode=am2&tag=thirtysevende-20&linkId=81f9b66993e3ac4f92c6dc5ecb843b38"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MGDXWRY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=thirtysevende-20" ></a><img src="//ir-na.amazon-adsystem.com/e/ir?t=thirtysevende-20&l=am2&o=1&a=B07MGDXWRY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" /> */
}

export default AmazonDetailListing;
